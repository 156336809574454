import React, { Component } from "react";
import { Link } from "react-router-dom";
import adzunaLogo from "../../logo/Adzuna-logo-80x50.png";
class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <nav className="z-depth-0">
          <div className="nav-wrapper">
            <div className="row">
              <div className="col s6">
                <div className="row">
                  <div className="col s12">
                    <Link
                      to="/"
                      style={{
                        fontFamily: "monospace",
                        marginLeft: "10px" 
                      }}
                      className="brand-logo left white-text"
                    > Indigenous Jobs Map
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col s6">
                <ul className="right hide-on-med-and-down">
                  <li>
                    <a href="https://www.adzuna.com.au/">
                    
                      <i className="right"><img src={adzunaLogo} width="80" height="50" style={{ marginTop: "5px" }} alt="Adzuna Logo"></img>
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
export default Navbar;
