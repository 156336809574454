import {
  SET_STUDENT_MAP_DATA,
  FILTER_STUDENT_MAP_DATA,
  SET_TABLE_DATA,
  UPDATE_OPTION_SPECIFIC_MEASURES,
  UPDATE_GEO_LEVEL,
  UPDATE_TYPE_MEASURE,
  UPDATE_SPECIFIC_MEASURE,
  UPDATE_FOE,
  UPDATE_SECTOR,
  UPDATE_NARROW_FOES,
  UPDATE_AQFS,
  SET_PROGRESS_BAR
} from "../actions/types";

const initialState = {
  loading: false,
  colors: [],
  legend_keys: [],
  data: null,
  statsState: null,
  statsNation: null,
  tableData: [],
  tableDisplayData: {displayData: [], selectedArea: "", selectedState: ""},
  tableDisplayFilters: {selectedTypeMeasure: '',
                        selectedSpecificMeasure: '', 
                        selectedSector: '',
                        selectedAQFs: [], selectedFoE: [],
                        selectedNarrowFoEs: []},
  optionTypeMeasures: [],
  selectedTypeMeasure:"",
  typeMeasuresVSSpecificMeasures: {},
  optionSpecificMeasures: [],
  selectedSpecificMeasure:"",
  optionGeoLevels: [],
  selectedGeoLevel: "",
  optionSectors: [],
  originalOptionSectors: [],
  selectedSector: "",
  optionFoEs: [],
  originalOptionFoEs: [], 
  selectedFoE: "",
  optionNarrowFoEs: [],
  selectedNarrowFoEs: [],
  optionAQFs: [],
  originalOptionAQFs: [],
  selectedAQFs: [],
  optionStudentAQFs: [],
  originalOptionStudentAQFs: [],
  FoEsVSNarrowFoEs:{}
};

function filterTableByArea(_selectedNarrowFoEs, area, selectedState, tableDataOriginal, statsState, statsNation) {
  let result = {};
  let displayData = [];
  let state_feature; 
  // console.log(area);
  // console.log(selectedState);
  for (var i=0; i<statsState.length; i++) {
    let feature = statsState[i];
    if (feature.region_name === selectedState) {
      state_feature = feature;
    }
    // get the nation counts for different Narrow FoEs
    if (_selectedNarrowFoEs.length > 0){  
      for (var j = 0; j<_selectedNarrowFoEs.length; j++){
        let narrowFoE = _selectedNarrowFoEs[j];
        let tmp_narrowFoE = narrowFoE.replace(/,/g,"").replace(/ /g,"_");
        if (tmp_narrowFoE in feature){
        }
      }
    }
  }; 

  for (var k=0; k<tableDataOriginal.length; k++) {
      let feature = tableDataOriginal[k];
      if (feature.region_name === area) {
        displayData.push({
          var_name: "Total", 
          area_count:feature.counts,
          state_count: state_feature.counts,
          nation_count: statsNation.counts
        });
        if (_selectedNarrowFoEs.length > 0){  
          displayData.push({
            var_name: "Narrow Field of Education Breakdown", 
            area_count: "",
            state_count: "",
            nation_count: ""
          });
          for (var l = 0; l<_selectedNarrowFoEs.length; l++){
            let narrowFoE = _selectedNarrowFoEs[l];
            let tmp_narrowFoE = narrowFoE.replace(/,/g,"").replace(/ /g,"_");
            displayData.push({
              var_name:narrowFoE, 
              area_count: tmp_narrowFoE in feature? feature[tmp_narrowFoE] : "<5",
              state_count: tmp_narrowFoE in state_feature? state_feature[tmp_narrowFoE]: "<5",
              nation_count: tmp_narrowFoE in statsNation? statsNation[tmp_narrowFoE] : "<5"
            });
          }
        }
        break; 
      }
    };
  result.displayData = displayData;
  result.selectedArea = area;
  result.selectedState = selectedState;
  return result;
}

function updateSpecificMeasures(
  selectedTypeMeasure,
  typeMeasuresVSSpecificMeasures
) {
  if (selectedTypeMeasure === null){
    return [];
  }else{
    return typeMeasuresVSSpecificMeasures[selectedTypeMeasure];
  };
}

function updateNarrowFoEs(
  selectedFoE,
  FoEsVSNarrowFoEs
){
  if (selectedFoE === null){
    return [];
  }else{
    return FoEsVSNarrowFoEs[selectedFoE];
  }
}

function updateFilters(_specificMeasure, _state){
  switch (_specificMeasure) {
    case "All workers":
      return Object.assign({}, _state, {
        selectedSpecificMeasure: _specificMeasure,
        optionAQFs:[],
        selectedAQFs: [],
        optionFoEs:[],
        selectedFoE: [],
        optionNarrowFoEs:[],
        selectedNarrowFoEs: [],
        optionSectors: _state.originalOptionSectors,
        selectedSector: []
      });
    case "Indigenous workers by sector of employment":
      return Object.assign({}, _state, {
        selectedSpecificMeasure: _specificMeasure,
        optionAQFs:[],
        selectedAQFs:[],
        optionFoEs:[],
        selectedFoE: [],
        optionNarrowFoEs:[],
        selectedNarrowFoEs: [],
        optionSectors: _state.originalOptionSectors,
        selectedSector: []
      }); 
    case "Indigenous workers by qualification":
      return Object.assign({}, _state, {
        selectedSpecificMeasure: _specificMeasure,
        optionSectors:[],
        selectedSector: [],
        optionFoEs:_state.originalOptionFoEs,
        selectedFoE: [],
        optionAQFs:_state.originalOptionAQFs,
        selectedAQFs: [],
        optionNarrowFoEs:[],
        selectedNarrowFoEs: []
      }); 
    case "Indigenous graduates":
        return Object.assign({}, _state, {
          selectedSpecificMeasure: _specificMeasure,
          optionSectors:[],
          selectedSector: [],
          optionFoEs:_state.originalOptionFoEs,
          selectedFoE: [],
          optionAQFs:_state.originalOptionStudentAQFs,
          selectedAQFs: [],
          optionNarrowFoEs:[],
          selectedNarrowFoEs: []
        }); 
    case "Indigenous focused job ads per thousand Indigenous workers":
      return Object.assign({}, _state, {
        selectedSpecificMeasure: _specificMeasure,
        optionSectors: [],
        selectedSector: [],
        optionFoEs: _state.originalOptionFoEs,
        selectedFoE: [],
        optionAQFs: _state.originalOptionAQFs,
        selectedAQFs: [],
        optionNarrowFoEs: [],
        selectedNarrowFoEs: []
      });
      case "Identified job ads per thousand Indigenous workers":
        return Object.assign({}, _state, {
          selectedSpecificMeasure: _specificMeasure,
          optionSectors: [],
          selectedSector: [],
          optionFoEs: _state.originalOptionFoEs,
          selectedFoE: [],
          optionAQFs: _state.originalOptionAQFs,
          selectedAQFs: [],
          optionNarrowFoEs: [],
          selectedNarrowFoEs: []
        });
      case "Indigenous cultural capability job ads per thousand Indigenous workers":
        return Object.assign({}, _state, {
          selectedSpecificMeasure: _specificMeasure,
          optionSectors: [],
          selectedSector: [],
          optionFoEs: _state.originalOptionFoEs,
          selectedFoE: [],
          optionAQFs: _state.originalOptionAQFs,
          selectedAQFs: [],
          optionNarrowFoEs: [],
          selectedNarrowFoEs: []
        });
      case "Indigenous applicants encouraged job ads per thousand Indigenous workers":
        return Object.assign({}, _state, {
          selectedSpecificMeasure: _specificMeasure,
          optionSectors: [],
          selectedSector: [],
          optionFoEs: _state.originalOptionFoEs,
          selectedFoE: [],
          optionAQFs: _state.originalOptionAQFs,
          selectedAQFs: [],
          optionNarrowFoEs: [],
          selectedNarrowFoEs: []
        });
      case "All job ads per thousand workers (regardless of Indigeneity)":
        return Object.assign({}, _state, {
          selectedSpecificMeasure: _specificMeasure,
          optionSectors: [],
          selectedSector: [],
          optionFoEs: _state.originalOptionFoEs,
          selectedFoE: [],
          optionAQFs: _state.originalOptionAQFs,
          selectedAQFs: [],
          optionNarrowFoEs: [],
          selectedNarrowFoEs: []
        });
    default:
      return Object.assign({}, _state, {
        selectedSpecificMeasure: _specificMeasure,
        optionSectors:_state.originalOptionSectors,
        selectedSector: [],
        optionAQFs:_state.originalOptionAQFs,
        selectedAQFs: [],
        optionFoEs:_state.originalOptionFoEs,
        selectedFoE: [],
        optionNarrowFoEs:[],
        selectedNarrowFoEs: []
      });
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROGRESS_BAR:
      return Object.assign({}, state, {
        loading: action.loading
      });
    case SET_STUDENT_MAP_DATA:
      return Object.assign({}, state, {
        // the data to display (with filter applied)
        loading: action.loading,
        data: action.data,
        tableData: action.tableData,
        statsState: action.statsState,
        tableDisplayFilters: {
          selectedTypeMeasure: action.tableDisplayFilters.selectedTypeMeasure,
          selectedSpecificMeasure: action.tableDisplayFilters.selectedSpecificMeasure, 
          selectedSector: typeof action.tableDisplayFilters.selectedSector === "undefined"? 
          state.tableDisplayFilters.selectedSector: action.tableDisplayFilters.selectedSector,
          selectedAQFs: typeof action.tableDisplayFilters.selectedAQFs === "undefined"? 
          state.tableDisplayFilters.selectedAQFs: action.tableDisplayFilters.selectedAQFs,
          selectedFoE: typeof action.tableDisplayFilters.selectedFoE === "undefined"? 
          state.tableDisplayFilters.selectedFoE: action.tableDisplayFilters.selectedFoE,
          selectedNarrowFoEs: typeof action.tableDisplayFilters.selectedNarrowFoEs === "undefined"? 
          state.tableDisplayFilters.selectedNarrowFoEs: action.tableDisplayFilters.selectedNarrowFoEs
        },
        tableDisplayData: {displayData: [], selectedArea: "", selectedState: ""},
        statsNation: action.statsNation,
        // the default color set in the server side
        colors: action.colors,
        // the legend keys
        legend_keys: action.legend_keys,
        // geoLevel: state.geoLevel,
        optionTypeMeasures: action.optionTypeMeasures,
        typeMeasuresVSSpecificMeasures: action.typeMeasuresVSSpecificMeasures,
        optionGeoLevels: action.optionGeoLevels,
        optionAQFs: action.optionAQFs,
        selectedAQFs: action.selectedAQFs,
        originalOptionAQFs: action.optionAQFs,
        optionStudentAQFs: action.optionStudentAQFs,
        originalOptionStudentAQFs: action.optionStudentAQFs,
        // the available selection of fields of education
        optionFoEs: action.optionFoEs,
        originalOptionFoEs: action.optionFoEs,
        selectedFoE: action.selectedFoE,
        FoEsVSNarrowFoEs: action.FoEsVSNarrowFoEs,
        // the available selection of sectors
        optionSectors: action.optionSectors,
        selectedSector: action.selectedSector,
        originalOptionSectors: action.optionSectors,
        selectedGeoLevel: action.selectedGeoLevel,
        selectedTypeMeasure: action.selectedTypeMeasure,
        optionSpecificMeasures: updateSpecificMeasures(
          action.selectedTypeMeasure,
          action.typeMeasuresVSSpecificMeasures
        ),
        selectedSpecificMeasure: action.selectedSpecificMeasure,
        selectedNarrowFoEs: action.selectedNarrowFoEs
      });
    case FILTER_STUDENT_MAP_DATA:
      return Object.assign({}, state, {
        loading: action.loading,
        data: action.data,
        colors: action.colors,
        tableData: action.tableData,
        tableDisplayFilters: {
          selectedTypeMeasure: action.tableDisplayFilters.selectedTypeMeasure,
          selectedSpecificMeasure: action.tableDisplayFilters.selectedSpecificMeasure, 
          selectedSector: typeof action.tableDisplayFilters.selectedSector === "undefined"? 
          state.tableDisplayFilters.selectedSector: action.tableDisplayFilters.selectedSector,
          selectedAQFs: typeof action.tableDisplayFilters.selectedAQFs === "undefined"? 
          state.tableDisplayFilters.selectedAQFs: action.tableDisplayFilters.selectedAQFs,
          selectedFoE: typeof action.tableDisplayFilters.selectedFoE === "undefined"? 
          state.tableDisplayFilters.selectedFoE: action.tableDisplayFilters.selectedFoE,
          selectedNarrowFoEs: typeof action.tableDisplayFilters.selectedNarrowFoEs === "undefined"? 
          state.tableDisplayFilters.selectedNarrowFoEs: action.tableDisplayFilters.selectedNarrowFoEs
        },
        tableDisplayData: {displayData: [], selectedArea: "", selectedState: ""},
        statsState: action.statsState,
        statsNation: action.statsNation,
        legend_keys: action.legend_keys,
        geoLevel: action.geoLevel,
        selectedNarrowFoEs: action.selectedNarrowFoEs
      });
    case SET_TABLE_DATA:
      return Object.assign({}, state, {
        tableDisplayData: filterTableByArea(
          state.selectedNarrowFoEs,
          action.selectedArea,
          action.selectedState,
          state.tableData,
          state.statsState,
          state.statsNation
        ),
      });
    case UPDATE_OPTION_SPECIFIC_MEASURES:
      return Object.assign({}, state, {
        selectedTypeMeasure: action.selectedTypeMeasure,
        optionSpecificMeasures: updateSpecificMeasures(
          action.selectedTypeMeasure,
          state.typeMeasuresVSSpecificMeasures
        )
      });
    case UPDATE_FOE:
      return Object.assign({}, state, {
        selectedFoE: action.selectedFoE,
        optionNarrowFoEs: updateNarrowFoEs(
          action.selectedFoE,
          state.FoEsVSNarrowFoEs
        )
      });
    case UPDATE_GEO_LEVEL:
      return Object.assign({}, state, {
        selectedGeoLevel: action.selectedGeoLevel
      });
    case UPDATE_TYPE_MEASURE:
      let options = updateSpecificMeasures(
        action.selectedTypeMeasure,
        state.typeMeasuresVSSpecificMeasures
      );
      let result = {};
      result.selectedTypeMeasure = action.selectedTypeMeasure;
      result.optionSpecificMeasures = options;
      result.selectedSpecificMeasure = options[0];
      let _result = updateFilters(result.selectedSpecificMeasure, state);
      result = Object.assign({}, state, _result, result);
      return result;
    case UPDATE_SPECIFIC_MEASURE:
      return updateFilters(action.selectedSpecificMeasure, state);
    case UPDATE_SECTOR:
      return Object.assign({}, state, {
        selectedSector: action.selectedSector
      });
    case UPDATE_NARROW_FOES:
      return Object.assign({}, state, {
        selectedNarrowFoEs: action.selectedNarrowFoEs
      });
    case UPDATE_AQFS:
      return Object.assign({}, state, {
        selectedAQFs: action.selectedAQFs
      });
    default:
      return state;
  }
}
