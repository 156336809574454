import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import ConnectedOptionsfield from "./Optionsfield";
import ConnectedDataTable from "./DataTable";
import "./Map.css";
import { requestJobs, filterTable } from "../../actions/studentMapActions";
import { connect } from "react-redux";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

mapboxgl.accessToken =
  "pk.eyJ1IjoiaGFvaHVpY2hlbjE5ODEiLCJhIjoiY2tuemJpeDgwMDNqbTJ2cnRzd3pjNW1lYSJ9.Tko-h6TDtJua7CYE5iPWSw";

const Map = (props) => {
  const mapContainerRef = useRef(null);
  const mapLegendRef = useRef(null);
  const [map, setMap] = useState(null);

  const { requestJobs, filterTable } = props;
  const [open, setOpen] = React.useState(true);
  // const [loadMoreOpen, setLoadMoreOpen] = React.useState(true);

  useEffect(() => {
    const lng = 135;
    const lat = -26;
    const zoom = 3;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom: zoom,
    });
    map.addControl(new mapboxgl.NavigationControl());
 
    map.on("load", () => {
      requestJobs();
      setMap(map);
    });

    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const paint = () => {
    if (map) {

      const { data, categoryColors, legend_keys, selectedSpecificMeasure } = props;

      if (map.getLayer("sa_color")) {
        map.off("click", "sa_color", filterTable);
        map.removeLayer("sa_color");
      }
      if (map.getSource("sa_stats")) {
        map.removeSource("sa_stats");
      }

      map.addSource("sa_stats", {
        type: "geojson",
        // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
        // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
        data: data,
      });

      map.addLayer({
        id: "sa_color",
        type: "fill",
        source: "sa_stats",
        layout: {
        },
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.9,
          "fill-outline-color": "#808080"
        },
      });

      // console.log(map.getLayer("sa_color"));
      map.on("click", "sa_color", filterTable);
      setMap(map);

      if (document.getElementById("MAP_LEGEND")) {
        mapLegendRef.current.removeChild(
          document.getElementById("MAP_LEGEND")
        );
      }
      var legendItems = document.createElement("div");
      legendItems.setAttribute("id", "MAP_LEGEND");

      var legendTitle = document.createElement("div");
      // title.style.backgroundColor = categoryColors[i];

      var span = document.createElement("span");
      span.innerHTML = selectedSpecificMeasure;
      legendTitle.appendChild(span);
      legendItems.appendChild(legendTitle);

      for (let i = 0; i < legend_keys.length; i++) {
        // create legend
        var item = document.createElement("div");
        var key = document.createElement("span");
        key.className = "legend-key";
        key.style.backgroundColor = categoryColors[i];

        var value = document.createElement("span");
        value.innerHTML = legend_keys[i];
        item.appendChild(key);
        item.appendChild(value);
        legendItems.appendChild(item);
      }

      mapLegendRef.current.appendChild(legendItems);
      
    }
  };

  useEffect(() => {   
    if (!props.data) return;
    paint();
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="row">
        <div className="col s3" style={{ paddingLeft: "30.250px" }}>
          <ConnectedOptionsfield />
        </div>
        <div className="col s5" style={{ position: "relative"}}>
          <div className="map-overlay">
            <div className="map-overlay-inner" ref={mapLegendRef}></div>
          </div>
          <div
            ref={mapContainerRef}
            style={{ height: "75vh", width: "100%" }}
          ></div>
        </div>
        <div className="col s4" style={{ paddingTop: "10.250px" }}>
          <Collapse in={open}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              click on a region of the map to generate a data table
            </Alert>
          </Collapse>
          <ConnectedDataTable />
          {/* <Collapse in={loadMoreOpen}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setLoadMoreOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              scroll down the table to load more
            </Alert>
          </Collapse> */}
          
          <div className="row">
            <p style ={{ fontSize:"80%", lineHeight:1 }} className="grey-text text-darken-1">
              Please be aware that differences in statistics are likely to reflect random factors when they are
              based on a small number of observations. For this reason, no
              colour-coding is used on the map where the difference between
              number of people or number of job ads is less than 5. In addition, where there are small numbers of people for a given region, the statistics have been modified slightly to preserve individuals' confidentiality. These modifications can lead to small discrepancies between regional, state and national statistics.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    data: state.student.data,
    categoryColors: state.student.colors,
    legend_keys: state.student.legend_keys,
    selectedSpecificMeasure: state.student.selectedSpecificMeasure
  };
}

const ConnectedMap = connect(mapStateToProps, { requestJobs, filterTable })(
  Map
);
export default ConnectedMap;
