import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Navbar from "./components/layout/Navbar";
import Bottombar from "./components/layout/Bottombar";
import Landing from "./components/layout/Landing";
import Acknowledgement from "./components/layout/Acknowledgement";
import StudentDisclaimer from "./components/layout/StudentDisclaimer";
import EmployerDisclaimer from "./components/layout/EmployerDisclaimer";
import ConnectedStudentMap from "./components/indigenous/Map";

import StaticContainer from "./components/layout/StaticContainer";


class App extends Component {
  render() {
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      // console.log(e);
      // console.log(e.oldURL);
      // console.log(e.newURL);
      const hashParts = window.location.hash.split('#');
      if (hashParts.length > 2) {
        const hash = hashParts.slice(-1)[0];
        if (document.querySelector("#".concat(hash)) != null){
          document.querySelector(`#${hash}`).scrollIntoView();
          window.location.hash = window.location.hash.replace("#"+hash, "");
        }
      }
    };

    // https://github.com/remix-run/react-router/issues/394
    // const scrollToAnchor = () => {
    //   const hashParts = window.location.hash.split('#');
    //   if (hashParts.length > 2) {
    //     const hash = hashParts.slice(-1)[0];
    //     document.querySelector(`#${hash}`).scrollIntoView();
    //   }
    // };
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    

    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
    

    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <Route exact path="/" component={Acknowledgement} />
            <Route exact path="/landing" component={Landing} />
            <Route exact path="/student_disclaimer_landing" component={StudentDisclaimer} />
            <Route exact path="/employer_disclaimer_landing" component={EmployerDisclaimer} />
            <Route exact path="/student_map" component={ConnectedStudentMap} />
            <Route exact path="/employer_map" component={ConnectedStudentMap} />
            <Route exact path="/student_map_2021" component={ConnectedStudentMap} />
            <Route exact path="/employer_map_2021" component={ConnectedStudentMap} />
            <Route exact path="/background" component={() => <StaticContainer name={"background"} />} />
            <Route exact path="/feedback" component={() => <StaticContainer name={"feedback"} />} />
            <Route exact path="/cite" component={() => <StaticContainer name={"cite"} />} />
            <Route exact path="/faq" component={() => <StaticContainer name={"faq"} />} />
            <Route exact path="/disclaimerStud" component={() => <StaticContainer name={"disclaimerStud"} />} />
            <Route exact path="/disclaimerEmp" component={() => <StaticContainer name={"disclaimerEmp"} />} />
            <Bottombar /> 
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;