import React, { Component } from "react";
import { Link } from "react-router-dom";

class Landing extends Component {
  render() {
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
<div className="col s12 center-align">
            <h4>
              <b>Indigenous Jobs Map</b>
            </h4>
            <p className="flow-text grey-text text-darken-1 left-align">
            Welcome to the Indigenous Jobs Map, an interactive map that: 
              <ul id="disclaimer-ul" className="left-align">
                  <li>Shows what types of jobs have been advertised in each region and what formal qualifications were needed in these roles.</li>
                  <li>Shows what formal qualifications and industry experience workers or graduates have across each region of Australia. </li>
              </ul>
            </p>
            <br />
            <div className="col s6">
              <Link
                to="/student_disclaimer_landing"
                style={{
                  width: "300px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Job Seeker Portal
              </Link>
            </div>
            <div className="col s6">
              <Link
                to="/employer_disclaimer_landing"
                style={{
                  width: "300px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Employer Portal
              </Link>
            </div>
          </div>
          {/* <div className="col s12 center-align">
          <div className="col s12 center-align">
            <h4>
              <b>Acknowledgement of Country</b>
            </h4>
            <p className="flow-text grey-text text-darken-1">
            The Indigenous Jobs Map project acknowledges Aboriginal and/or Torres Strait Islander peoples as the Traditional Owners and Custodians of the land, waterways, seas and skies on which our lives and work take place. We pay respect to Elders past and present. Aboriginal and Torres Strait Islander peoples continue to work on and with Country and have done so since time immemorial. The Indigenous Jobs Map was created virtually across many different Countries; as such, we would like to acknowledge all unceded lands across the continent known as Australia.
            </p>
            <br />
            <div className="col s12">
              <Link
                to="/disclaimer_landing"
                style={{
                  width: "300px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Disclaimer
              </Link>
            </div>
          </div>
          {/* <div className="col s12 center-align">
            <p
              style={{ fontSize: "15px" }}
              className="flow-text grey-text text-darken-1"
            >
              Please note: The website and our research is still under
              development. We plan to deliver further improvements in the future
              but in the meantime we strongly recommend using other datasets to
              inform your decision making rather than placing reliance on the
              data from the skills dashboard.
            </p>
          </div> */}
        </div>
      </div>
    );
  }
}
export default Landing;
