import React, { Component } from "react";
import { Link } from "react-router-dom";

class StudentDisclaimer extends Component {
  render() {
    return (
      <div style={{ height: "75vh"}} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align" style={{ height: "60vh", overflow: 'auto'}}>
            <h4>
              <b>For your information:</b>
            </h4>
            <p className="left-align">The material and information contained on this website is for general information only.
                        You should not rely solely upon this information to make career or life choices. We recommend that you seek advice from a range of
                        trusted people and institutions to identify suitable and sustainable career choices and
                        pathways. Some issues to be aware of: 
            </p>
            <ul id="disclaimer-ul" className="left-align">
                <li> Technology developments can have a major impact on the types of workers and skills that are
                needed in the future. If you are interested in understanding the effect of these
                technology developments, there are websites and reports that you can consult to
                understand the risk of automation for specific occupations. 
                </li> 
                <li > We do not know whether employers who specifically seek Indigenous talent via their job
                  advertisements offer a culturally safe work environment. 
                </li> 
                <li > Our figures on the number of Aboriginal and / or Torres Strait Islander people studying or
                working in a particular field only capture those people who chose to identify as
                Aboriginal and / or Torres Strait Islander in the reports that the Indigenous Jobs Map draws upon. 
                </li> 
                <li > Machine learning was used to identify Indigenous-focused job ads. We can expect some errors in the classifications that the machine provided. Even human experts sometimes find it difficult to make these judgements. 
                </li> 
                <li > The Indigenous Jobs Map counts the number of people studying and working in a given region at the time when
                the data was collected. The actual pool of Indigenous talent available to employers may
                be smaller or larger due to people migrating to different regions or being willing to
                commute to a workplace outside of the region in which they live. 
                </li> 
                <li > The dashboard only captures jobs as defined by organisations and institutions. It does not
                recognise other types of jobs, roles and work that might be defined by Aboriginal and / or
                Torres Strait Islander peoples. 
                </li>
            </ul>
            <br />
          </div>
          {/* <div className="col s12 center-align">
            <p
              style={{ fontSize: "15px" }}
              className="flow-text grey-text text-darken-1"
            >
            </p>
          </div> */}
          <div className="col s12 center-align">
              <Link
                to="/student_map"
                style={{
                  width: "400px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Launch the job seeker map
              </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default StudentDisclaimer;
