import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { connect } from "react-redux";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SimpleModal = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [body, setBody] = React.useState("");

  const { selectedTypeMeasure } = props;

  useEffect(() => {
    let bodyText = (
      <div style={modalStyle} className={classes.paper}>
        <h4 id="simple-modal-title">Specific Measures</h4>
        <p id="simple-modal-description">
          Please select the Type of Measure first.
        </p>
      </div>
    );
    setBody(bodyText);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.selectedTypeMeasure) return;
    const updateBody = () => {
      let bodyText = "";
      switch (selectedTypeMeasure) {
        case "Number of people":
          bodyText = (
            <div style={modalStyle} className={classes.paper}>
              <h4 id="simple-modal-title">{selectedTypeMeasure}</h4>
              <p id="simple-modal-description">
                </p>
              <p>
                The specific measures you can select in this category include:{" "}
              </p>
              <ul id="disclaimer-ul" className="left-align">
                <li> <b>All workers</b>: Counts of both Indigenous Australians and non-Indigenous Australians who were employed or actively seeking employment, captured from the Australian Bureau of Statistics 2021 Census data  </li>
                <li><b>Indigenous workers by sector of employment</b>: Counts of Indigenous workers by sector of employment, captured from the Australian Bureau of Statistics 2021 Census data</li>
                <li><b>Indigenous workers by qualification</b>:  Counts of Indigenous workers by highest formal qualification held, captured from the Australian Bureau of Statistics 2021 Census data</li>
		            <li><b>Indigenous graduates</b>: Counts of Indigenous Australians who completed an accredited VET or University qualification between 2016 and 2020 </li>
              </ul>
            </div>
          );
          setBody(bodyText);
          break;
        case "Number of job ads":
          bodyText = (
            <div style={modalStyle} className={classes.paper}>
              <h4 id="simple-modal-title">{selectedTypeMeasure}</h4>
              <p id="simple-modal-description">
                The Map draws upon a national database of job ads data kindly provided by Adzuna Australia. This dataset includes more than ten million job ads captured
                between January 1 2016 and December 31 2020. 
              </p>
              <ul id="disclaimer-ul" className="left-align">
                <li><b>All job ads</b>: Counts of all job ads captured by Adzuna Australia between 2016 and 2020.</li>
                <li>
                  <b>Identified job ads</b>: Counts of job ads which specify that only Indigenous peoples can apply for the role or that Indigenous applicants will be given preference in the selection process
                </li>
                <li><b>Indigenous cultural capability job ads</b>: Counts of job ads where Indigenous
                  cultural capability or experience working with Indigenous peoples is required in the role 
                  </li>
                <li><b>Indigenous applicants encouraged</b>:  Counts of job ads which state that Indigenous peoples are encouraged to apply for the position 
                </li>
              </ul>
            </div>
          );
          setBody(bodyText);
          break;
        case "Number of job ads per thousand workers":
          bodyText = (
            <div style={modalStyle} className={classes.paper}>
              <h4 id="simple-modal-title">{selectedTypeMeasure}</h4>
              <p id="simple-modal-description">
                The map can be used to understand whether the number of jobs
                being advertised is high or low relative to the number of people
                working or studying in that region. Because the number of workers in a region is usually much larger
                than the number of jobs advertised in that region, we report on
                the number of job ads per thousand people.
              </p>
              <ul id="disclaimer-ul" className="left-align">
                <li><b>Indigenous focused job ads per thousand Indigenous workers</b>: Job ads are classified
                  as “Indigenous focused” if they are for Identified positions, if they require Indigenous cultural capability, or
                  if the job ad states that Indigenous peoples are encouraged to apply for the position.
                </li>
                <li><b>Identified job ads per thousand Indigenous workers</b>: Job
                  ads are classified as "Identified" if
                  they are only open to Indigenous peoples or if Indigenous peoples
                  are given priority in the selection process.
                </li>
                <li><b>Indigenous cultural capability job ads per thousand Indigenous workers</b>: Job ads are
                  classified as requiring “Indigenous cultural capability” if the job ad states that 
                  Indigenous cultural capability or experience working with Indigenous peoples is required in the role.
                </li>
                <li><b>Indigenous applicants encouraged job ads per thousand Indigenous workers</b>: Job
                  ads are classified as “Indigenous applicants encouraged” if
                  the job ad states that Indigenous peoples are encouraged to apply for the position.
                </li>
                <li><b>All job ads per thousand workers</b>: All job ads and workers (regardless of their Indigeneity) are included if you select this option. 
                </li>
              </ul>
            </div>
          );
          setBody(bodyText);
          break;
        default:
          bodyText = (
            <div style={modalStyle} className={classes.paper}>
              <h4 id="simple-modal-title">{selectedTypeMeasure}</h4>
              <p id="simple-modal-description">
                The Indigenous Jobs Map allows you to compare geographic regions
                on a range of measures. In order to suggest the most relevant
                measures, we need to know what type of information you are most
                interested in. </p>
 		            <ul id="disclaimer-ul" className="left-align">
                  <li><b>Number of People</b>: Select this option if you want to know how many workers or graduates are in a region.</li>
                  <li><b>Number of Job ads</b>: Select this option if you want to know how many jobs have been advertised in a region.</li>
                  <li><b>Number of job ads per thousand workers</b>: Select this option if you want to understand whether the number of jobs
                  being advertised is high or low relative to the number of people
                  working or studying in that region.</li>
                </ul>
               </div>
          );
          setBody(bodyText);
      }
      // document.getElementById("Specific-Measure-Info").innerHTML = bodyText;
    };

    updateBody();
  }, [props.selectedTypeMeasure]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <InfoIcon style={{ marginTop: "4rem" }} onClick={handleOpen}></InfoIcon>
      <Modal
        id="Specific-Measure-Info"
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >{body}</Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    selectedTypeMeasure: state.student.selectedTypeMeasure,
  };
}

const ConnectedSimpleModal = connect(mapStateToProps)(SimpleModal);
export default ConnectedSimpleModal;
