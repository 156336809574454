import React, { Component } from "react";
import { Link } from "react-router-dom";
import adzunaLogo from "../../logo/Adzuna-logo-80x75.png";
import d61Logo from "../../logo/CSIRO_Logo.svg.png";

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';


class Bottombar extends Component {
  state = { isHovered: false };

  showTooltip = () => {
    this.setState({ isHovered: true });
  };

  hideTooltip = () => {
    this.setState({ isHovered: false });
  };

  render() {
    return (
       <footer className="page-footer lighten-5" style={{ paddingTop: "0px", bottom:"0px", left:"0px", width:"100%" }}>
          <div className="row container valign-wrapper lighten-5al" style={ {marginBottom: '0px'}}>
              <div className="col m12 s3 l3">
                <a
                  href="https://www.csiro.au/"
                  style={{
                    fontFamily: "monospace"
                  }}
                  className="col brand-logo left black-text"
                  >
                  <img src={d61Logo} width="75" height="75" alt="Data61 Logo"></img>
                </a>
                <a
                  href="https://www.adzuna.com.au/"
                  style={{
                    fontFamily: "monospace"
                  }}
                  className="col brand-logo left black-text"
                  >
                  <img src={adzunaLogo} width="80" height="75" margin-bottom="12" alt="Adzuna Logo"></img> 
                </a>
              </div>
              <div className="col m12 s3 l3">
                <ul>
                  <li><Link className="white-text text-darken-2" to="/student_map">Job Seeker Portal</Link></li>
                  <li><Link className="white-text text-darken-2" to="/employer_map">Employer Portal</Link></li>
                  <li><Link className="white-text text-darken-2" to="/background">Background</Link></li>
                  <li><a className="white-text text-darken-2" href="https://www.csiro.au/en/research/technology-space/data/indigenous-jobs-map" target="_blank" 
                  rel="noopener noreferrer">Demo Video</a></li>
                </ul>
              </div>
              <div className="col m12 s3 l3">
                <ul>
                  <li><Link className="white-text text-darken-2" to="/faq">FAQ</Link></li>
                  <li><Link className="white-text text-darken-2" to="/cite">Citation</Link></li>
                  <li><Link className="white-text text-darken-2" to="/feedback">Feedback</Link></li>
                  <li><a className="white-text text-darken-2" href="https://www.csiro.au/en/about/Corporate-governance/Access-to-information" target="_blank" 
                  rel="noopener noreferrer">Access to information</a></li>
                </ul>
              </div>
              <div className="col m12 s3 l3" style={{ position: 'relative' }}>
                <iframe title="unique" src="//player.vimeo.com/video/846845525" width="240" height="100%" allow="autoplay; allowFullScreen " style={{ border: '0' }} allowFullScreen></iframe>
                <Tooltip 
                  title={
                    <Typography style={{ fontSize: '1.2rem' }}>Explore the Indigenous Jobs Map demo to see how job seekers can find where their skills are in demand and how employers can enhance their Indigenous recruitment strategies.</Typography>
                  } placement="top">
                  <InfoIcon style={{  
                    color: 'black', 
                    position: 'absolute', 
                    top: 10, 
                    right: 80, 
                    cursor: 'pointer' 
                  }} 
                  fontSize="large"
                  />
                </Tooltip>
              </div>
          </div>
          <div className="footer-copyright black valign-wrapper row" style={{
            padding: "0px", marginBottom: "0px"}}>
            <div className="container row">
              <ul className="list-inline ">
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies/Legal/Copyright">Copyright</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies/Legal/Legal-notice">Legal notice and disclaimer</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies/Privacy">Your privacy</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies">Policy & Guidelines</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/about/Policies/Legal/Accessibility">Accessibility</a>
                  </li>
                  <li className ="col">
                              <a className="white-text" href="https://www.csiro.au/en/contact">Contact us</a>
                  </li>
              </ul>
            </div>
          </div>
      </footer>
    );
  }

}
export default Bottombar;
