import React, { Component } from "react";
import { Link } from "react-router-dom";

class Acknowledgement extends Component {
  render() {
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align">
            <h4>
              <b>Acknowledgement of Country</b>
            </h4>
            <p className="flow-text grey-text text-darken-1">
            The Indigenous Jobs Map project acknowledges Aboriginal and Torres Strait Islander peoples as the Traditional Owners and Custodians of the land, waterways, seas and skies on which our lives and work take place. We pay respect to Elders past and present. Aboriginal and Torres Strait Islander peoples continue to work on and with Country and have done so since time immemorial. The Indigenous Jobs Map was created virtually across many different countries, as such we would like to acknowledge all unceded lands across the continent known as Australia.
            </p>
            <br />
            <div className="col s12">
              <Link
                to="/landing"
                style={{
                  width: "300px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn btn-large waves-effect waves-light hoverable blue accent-3"
              >
                Proceed to the map
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Acknowledgement;
