import axios from "axios";
import { SET_STUDENT_MAP_DATA, FILTER_STUDENT_MAP_DATA, 
  SET_TABLE_DATA, UPDATE_OPTION_SPECIFIC_MEASURES,
  UPDATE_GEO_LEVEL, UPDATE_TYPE_MEASURE, UPDATE_SPECIFIC_MEASURE,
  UPDATE_FOE, UPDATE_SECTOR, UPDATE_NARROW_FOES, UPDATE_AQFS, SET_PROGRESS_BAR} from "./types";

// Set map data
export const setProgressBar = (is_loading) => {
  return {
    type: SET_PROGRESS_BAR,
    loading: is_loading
  }
}

export const setMapData = data => {
  return {
    type: SET_STUDENT_MAP_DATA,
    loading: false,
    colors: data.colors,
    legend_keys: data.legend_keys,
    data: data.data,
    tableData: data.tableData,
    tableDisplayFilters: {
      selectedTypeMeasure: data.selectedTypeMeasure,
      selectedSpecificMeasure: data.selectedSpecificMeasure, 
      selectedSector: data.selectedSector,
      selectedAQFs: data.selectedAQFs, 
      selectedFoE: data.selectedFoE,
      selectedNarrowFoEs: data.selectedNarrowFoEs},
    statsState: data.statsState,
    statsNation: data.statsNation,
    optionTypeMeasures: data.typeMeasures,
    selectedTypeMeasure: data.selectedTypeMeasure,
    typeMeasuresVSSpecificMeasures: data.typeMeasuresVSSpecificMeasures,
    // update the measure filter
    selectedSpecificMeasure: data.selectedSpecificMeasure,
    optionGeoLevels : data.geoLevels,
    // update the geo filter
    selectedGeoLevel: data.selectedGeoLevel,
    optionSectors: data.sectors,
    selectedSector: "",
    optionFoEs: data.FoEs,
    selectedFoE: "",
    FoEsVSNarrowFoEs:data.FoEsVSNarrowFoEs,
    optionAQFs: data.AQFs,
    selectedAQFs: [],
    optionStudentAQFs: data.studentAQFs,
    selectedNarrowFoEs: []
  };
};

// Set map data
export const filterMapData = (data) => {
  return {
    type: FILTER_STUDENT_MAP_DATA,
    loading: false,
    colors: data.colors,
    legend_keys: data.legend_keys,
    data: data.data,
    tableData: data.tableData,
    tableDisplayFilters: {
      selectedTypeMeasure: data.selectedTypeMeasure,
      selectedSpecificMeasure: data.selectedSpecificMeasure, 
      selectedSector: data.selectedSector,
      selectedAQFs: data.selectedAQFs, 
      selectedFoE: data.selectedFoE,
      selectedNarrowFoEs: data.selectedNarrowFoEs},
    statsState: data.statsState,
    statsNation: data.statsNation,
    geoLevel:data.geoLevels,
    selectedNarrowFoEs:data.selectedNarrowFoEs
  };
};

// filter table data
export const filterTable = (e) => {
  // let area = e.features[0].properties[document.getElementById("control-geo-levels").value+"_NAME16"];
  let area = e.features[0].properties["SA3_NAME16"];
  let state = e.features[0].properties["STE_NAME16"];
  // console.log("filterTable:" + area + " state: "+ state);
  return {
    type: SET_TABLE_DATA,
    selectedArea: area,
    selectedState: state
  };
};

// request data
export const requestJobs = () => async dispatch => {
  var url = window.location.href;
  url = url.split("/");
  url = url[url.length-1];
  var year = url;
  year = year.split("_");
  year = year[year.length-1];
  if(isNaN(parseFloat(year))){
    year = 0;
  }
  var req = {
    "initRequest":1, 
    "map_type":url,
    "map_year":year,
    "AQFs": [],
    "FoE": [], 
    "sector": [],
    "narrowFoEs": []
  };
  dispatch(setProgressBar(true));
  const {data} = await axios.post("/api/indigenous/student/data", req);
  dispatch(setMapData(data));   
};

export const updateGeoLevel=(value) => {
  return {
    type: UPDATE_GEO_LEVEL,
    selectedGeoLevel: value
  };
};

export const updateTypeMeasure=(value) => {
  return {
    type: UPDATE_TYPE_MEASURE,
    selectedTypeMeasure: value
  };
};

export const updateSpecificMeasure=(value) => {
  return {
    type: UPDATE_SPECIFIC_MEASURE,
    selectedSpecificMeasure: value
  };
};

export const updateFoE=(value) => {
  return {
    type: UPDATE_FOE,
    selectedFoE: value
  };
};

export const updateSector=(value) => {
  return {
    type: UPDATE_SECTOR,
    selectedSector: value
  };
};

export const updateNarrowFoEs=(value) => {
  return {
    type: UPDATE_NARROW_FOES,
    selectedNarrowFoEs: value
  };
};

export const updateAQFs=(value) => {
  return {
    type: UPDATE_AQFS,
    selectedAQFs: value
  };
};


// Filter map by years and themes
export const filterMap = (specificMeasure, AQFs, FoE, sector, geoLevel, narrowFoEs) => async dispatch => {
  var url = window.location.href;
  url = url.split("/");
  url = url[url.length-1];
  var year = url;
  year = year.split("_");
  year = year[year.length-1];
  if(isNaN(parseFloat(year))){
    year = 0;
  }
  var req = {
    "map_year":year,
    "specificMeasure":specificMeasure, 
    "AQFs": AQFs,
    "FoE": FoE, 
    "sector": sector,
    "geoLevel": geoLevel,
    "narrowFoEs": narrowFoEs
  };
  dispatch(setProgressBar(true));
  const {data} = await axios.post("/api/indigenous/student/data", req);
  dispatch(filterMapData(data));    
};

export const updateOptionSpecificMeasures = (measure) => {
  return {
    type: UPDATE_OPTION_SPECIFIC_MEASURES,
    selectedTypeMeasure: measure
  };
}
