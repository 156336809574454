import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import InfoIcon from "@material-ui/icons/InfoOutlined";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal() {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h4 id="simple-modal-title">Type of measure:</h4>
      <p id="simple-modal-description">
      <ul><li><b> Number of People</b>: Select this option to find out how many people are working or studying in a given region </li>
	<li><b>Number of Job ads</b>: Select this option to find out what types of jobs have been advertised in a given region</li>
	<li><b>Number of job ads per thousand workers</b>: Select  this option to see where demand for talent is high relative to the number of people working in the region</li></ul>	
      </p>
    </div>
  );

  return (
    <div>
      <InfoIcon style={{ marginTop: "4rem" }} onClick={handleOpen}></InfoIcon>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );// eslint-disable-line react-hooks/exhaustive-deps
}


